import http from "./httpService";
import config from "./config";
const apiEndpoint = config.apiUrl + "/review";
export function addReviews(user) {
  return http.post(apiEndpoint, {
    fullname: user.fullname,
    comments: user.comments,
    rating: user.rating,
  });
}

function userUrl(id) {
  return `${apiEndpoint}/${id}`;
}

// export function getCartById(userId) {
//   return http.get(userUrl(userId));
// }
export function getReviews() {
  return http.get(apiEndpoint);
}

// export function deleteCartById(userId) {
//   return http.delete(userUrl(userId));
// }

export default {
  getReviews,
  addReviews,
  //   addToCart,
  //   getCartById,
  //   deleteCartById,
};
