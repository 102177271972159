import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import { getReviews, addReviews } from "../services/reviewService";
import auth from "../services/authService";
import img1 from "../images/page-img/03.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import Notiflix from "notiflix";
function ReviewsComponent() {
  let user = auth.getCurrentUser();
  const [reviews, setReviews] = useState([]);
  const [default_reviews, setDefaultReviews] = useState([]);
  const [fullname, setFullname] = useState("");
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [filtered_rating, setFilteredRating] = useState(0);
  const filterReview = (value) => {
    if (value == "") {
      setReviews(default_reviews);
    } else {
      let filtered = default_reviews.filter((val) => val.rating == value);
      setReviews(filtered);
    }
  };
  useEffect(() => {
    async function loadItems() {
      let { data } = await getReviews();
      setReviews(data);
      setDefaultReviews(data);
    }
    loadItems();
  }, []);
  const doSubmit = async () => {
    if (!user) {
      Notiflix.Notify.failure("Please Login to Add Reviews");
    } else {
      if (fullname.length <= 0) {
        Notiflix.Notify.failure("FullName cannot be Empty");
      } else if (comment.length <= 0) {
        Notiflix.Notify.failure("Comment cannot be Empty");
      } else if (rating == 0) {
        Notiflix.Notify.failure("Please Select Rating");
      } else {
        let data = {
          fullname: fullname,
          comments: comment,
          rating: rating,
        };
        try {
          await addReviews(data);
          Notiflix.Notify.success("Added Successfully");
        } catch (error) {
          Notiflix.Notify.failure(error.message);
        }
      }
    }
  };
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div id="content-page" style={{ marginTop: "90px" }}>
          <div className="container-fluid">
            <div id="show_bg_2">
              <h3 className="text-white text-center mt-5">CUSTOMER REVIEWS</h3>
            </div>
            <div class="row mt-5 mb-2 gap-5">
              <div
                class="mx-auto col-md-8 col-lg-8 col-sm-12"
                style={{ minHeight: "100px" }}
              >
                <div className="row mb-3">
                  <div className="col-6">
                    <h5 className="text-center mb-3">CUSTOMER REVIEWS</h5>
                  </div>

                  <div className="col-6">
                    <select
                      onChange={(e) => filterReview(e.target.value)}
                      class="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option value="">Select Rating</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                {reviews.map((item) => (
                  <div className="col-12 card p-3 mb-2">
                    <div>{item.fullname}</div>
                    <div>
                      {" "}
                      <i
                        class="fa fa-star"
                        style={{ fontSize: "20px", color: "#FFD202" }}
                      ></i>
                      <i
                        class="fa fa-star"
                        style={{ fontSize: "20px", color: "#FFD202" }}
                      ></i>
                      <i
                        class="fa fa-star"
                        style={{ fontSize: "20px", color: "#FFD202" }}
                      ></i>
                      <i class="fa fa-star" style={{ fontSize: "20px" }}></i>
                      <i class="fa fa-star" style={{ fontSize: "20px" }}></i>
                    </div>
                    <div>{item.comments}</div>
                    <label className="text-muted">{item.created}</label>
                  </div>
                ))}

                {reviews.length <= 0 && (
                  <div className="card text-center p-3">
                    There are no ratings in this category
                  </div>
                )}
              </div>
            </div>
            <div class="row">
              <div className="col-12">
                {" "}
                <h3 className="text-center">Add Review</h3>
              </div>
              <div
                class="mx-auto col-md-8 col-lg-8 col-sm-12"
                style={{ minHeight: "400px" }}
              >
                <div class="form-group">
                  <label for="email">FullName:</label>
                  <input
                    onChange={(e) => setFullname(e.target.value)}
                    type="text"
                    class="form-control"
                    id="email1"
                  />
                </div>
                <div class="form-group">
                  <label for="email">Review Text</label>
                  <input
                    onChange={(e) => setComment(e.target.value)}
                    type="text"
                    class="form-control"
                    id="email1"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Select Rating</label>
                  <select
                    onChange={(e) => setRating(e.target.value)}
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option selected="" disabled="">
                      Select Rating
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div class="form-group">
                  <button
                    onClick={() => doSubmit()}
                    className="btn  btn-success"
                  >
                    Add Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ReviewsComponent;
