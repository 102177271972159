import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/09.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import { getItems, getItemsById } from "../services/itemService";
import { NavLink, useParams } from "react-router-dom";
import Notiflix from "notiflix";
import auth from "../services/authService";

import { getCartById, deleteCartById } from "../services/cartService";
import PaystackComponent from "./PaystackComponent";

function CartItemsComponent(props) {
  let { id } = useParams();
  let user = auth.getCurrentUser();
  let cartItems = [];
  const [cart_item, setCartItems] = useState([]);
  const [item_name, setItemName] = useState("");
  const [item_avatar, setItemAvatar] = useState("");
  const [item_category, setItemCategory] = useState("");
  const [item_desc, setItemDesc] = useState("");
  const [item_price, setItemPrice] = useState("");
  const [subtotal, setSubTotal] = useState(0);
  const [item_quantity, setItemQuantity] = useState(1);
  useEffect(() => {
    async function loadItems() {
      let totalPrice = 0;
      if (user) {
        let { data } = await getCartById(user.user_id);
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          totalPrice += element.item_price * element.quantity;
        }
        console.log(data.length);
        setSubTotal(totalPrice);
        setCartItems(data);
      }
    }
    loadItems();
  }, []);
  const removeFromCart = async (id) => {
    if (!user) {
      Notiflix.Notify.failure("Please Login to Add Item to Cart");
      return;
    } else {
      try {
        Notiflix.Confirm.show(
          "Notiflix Confirm",
          "Are you sure you to Remove Item from Cart?",
          "Yes",
          "No",
          async () => {
            let { data } = await deleteCartById(id);
            Notiflix.Report.success(
              "Response",
              "Item Removed from Cart Successfully",
              "OK"
            );
            window.location.reload();
          },
          () => {
            // alert("If you say so...");
          },
          {}
        );
      } catch (error) {
        Notiflix.Notify.failure("Item could not be Removed");
      }
    }
  };

  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div
          id="content-page"
          style={{ marginTop: "90px", minHeight: "650px" }}
        >
          <div className="container-fluid">
            <br />
            <div className="text-center m-2">
              {/* <div
                class="btn-group mb-3"
                role="group"
                aria-label="Basic example"
              >
                <button
                  onClick={reloadData}
                  type="button"
                  class="btn btn-outline-success"
                >
                  All Items
                </button>
                <button
                  onClick={() => filterData("Continental Dish")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  Continental Dish
                </button>
                <button
                  onClick={() => filterData("African Dish")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  African Dish
                </button>
                <button
                  onClick={() => filterData("Oriental Dish")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  Oriental Dish
                </button>
              </div> */}
            </div>
            {/* <h3 className="text-center m-3">OUR MENU</h3> */}
            <div class="row m-3">
              <div className="mx-auto col-lg-6 col-md-6 col-sm-12">
                {cart_item.map((item) => (
                  <div key={item.item_id} class="item">
                    <div class="card iq-mb-3">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src={item.item_avatar}
                        class="card-img-top"
                        alt="#"
                      />
                      <div class="card-body">
                        <h4 class="card-title">
                          {item.item_name}
                          <br />
                          &pound; {item.item_price}
                        </h4>
                        <p class="card-text">{item.item_desc}</p>
                        <p class="card-text">
                          <small class="text-muted">{item.item_category}</small>
                          <div class="form-group row">
                            <label
                              class="control-label col-sm-2 align-self-center mb-0"
                              for="email"
                            >
                              Quantity:
                            </label>
                            <div class="col-sm-6">
                              <input
                                value={item.quantity}
                                onChange={(e) =>
                                  setItemQuantity(e.target.value)
                                }
                                min="1"
                                type="number"
                                class="form-control"
                                id="email"
                                placeholder="Quantity"
                              />
                            </div>
                            <div className="col-sm-4">
                              {" "}
                              <button
                                onClick={() => removeFromCart(item.cart_id)}
                                style={{ float: "right" }}
                                type="button"
                                class="btn btn-danger m-1"
                              >
                                Remove From Cart
                              </button>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <form class="form-horizontal" action="#">
                  <div class="form-group row">
                    <label
                      class="control-label col-sm-2 align-self-center mb-0"
                      for="email"
                    >
                      Subtotal:
                    </label>
                    <div class="col-sm-10">
                      <label> &pound; {subtotal}</label>
                    </div>
                  </div>

                  <div class="form-group">
                    <NavLink
                      to={"/payment-details/" + subtotal}
                      style={{ float: "right" }}
                      type="button"
                      className="btn btn-success m-1"
                    >
                      Make Payment
                    </NavLink>
                    {/* <PaystackComponent /> */}
                  </div>
                </form>
              </div>
            </div>
            {cart_item.length == 0 && (
              <div class="row">
                <div class="mx-auto col-sm-6">
                  <div class="card iq-mb-3">
                    <div class="card-body">
                      <h4 class="card-title">
                        There are no Items in your cart
                      </h4>
                      <p class="card-text">Add Items to you cart </p>
                      <a href="/" class="btn btn-primary btn-block">
                        Go Home
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default CartItemsComponent;
