import React from "react";
import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import OwlCarousel from "react-owl-carousel";
import DemoCarousel from "./Common/DemoCarousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import img1 from "../images/page-img/03.jpg";
import imgmap from "../ukmap.png";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import { getItems } from "../services/itemService";
import { NavLink } from "react-router-dom";
function IndexComponent() {
  const [items, setItems] = useState([]);
  useEffect(() => {
    async function loadItems() {
      let { data } = await getItems();
      setItems(data);
    }
    loadItems();
  }, []);
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div id="content-page" style={{ marginTop: "90px" }}>
          <div className="container-fluid">
            <div id="show_bg_2">
              <h3 className="text-white">Welcome to Blacky Restaurant</h3>
            </div>
            {/* <div
              className="card"
              style={{ height: "300px", overflow: "hidden" }}
            >
              <img
                src="https://images.pexels.com/photos/1410236/pexels-photo-1410236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                class="img-fluid"
                alt=""
                style={{ height: "500px", objectFit: "cover" }}
              />
              <h2 class="text-white">Congratulations Nik</h2>
              <p class="text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. remaining essentially unchanged.
              </p>
              <button type="button" class="btn iq-bg-primary">
                Read More
              </button>
            </div> */}
            <div class="row mt-5 mb-5 gap-5">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h5 className="text-center mb-4">Explore Options</h5>
                <div className="card"></div>
                <DemoCarousel />
                {/* <OwlCarousel className="owl-theme" loop margin={10} nav>
                  {items.map((item) => (
                    <NavLink to={"/view-item/" + item.item_id}>
                      <div key={item.item_id} class="item">
                        <div class="card iq-mb-3">
                          <img
                            style={{ height: "200px", objectFit: "cover" }}
                            src={item.item_avatar}
                            class="card-img-top"
                            alt="#"
                          />
                          <div class="card-body">
                            <h4 class="card-title">
                              {item.item_name}
                              <br />
                              &pound; {item.item_price}
                            </h4>
                            <p class="card-text">{item.item_desc}</p>
                            <p class="card-text">
                              <small class="text-muted">
                                {item.item_category}
                              </small>
                              <button
                                style={{ float: "right" }}
                                className="btn btn-sm btn-success"
                              >
                                VIEW ITEM
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  ))}
                </OwlCarousel> */}

                {/* <div
                  style={{ height: "400px" }}
                  className="owl-carousel mt-4"
                  data-autoplay="true"
                  data-loop="true"
                  data-nav="true"
                  data-dots="true"
                  data-items="3"
                  data-margin="30"
                >
                  {items.map((item) => (
                    <div class="item">
                      <div class="card iq-mb-3">
                        <img
                          src={item.item_avatar}
                          class="card-img-top"
                          alt="#"
                        />
                        <div class="card-body">
                          <h4 class="card-title">Card title</h4>
                          <p class="card-text">
                            This is a wider card with supporting text below as a
                            natural lead-in to additional content. This content
                            is a little bit longer.
                          </p>
                          <p class="card-text">
                            <small class="text-muted">
                              Last updated 3 mins ago
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
              <div className="col-1"></div>
              <div class="col-md-6 col-lg-5 col-sm-12">
                <div className="card mt-5">
                  <img
                    style={{ height: "400px", objectFit: "cover" }}
                    src={imgmap}
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <h5 className="text-center">
                  Monday-Thursday 1pm-12am <br />
                  Friday-Saturday 1pm-4am
                  <br />
                  Sunday 1pm-12am
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default IndexComponent;
