import { ReactFragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import IndexComponent from "./components/IndexComponent";
import AboutUsComponent from "./components/AboutUsComponent";
import ContactUsComponent from "./components/ContactUsComponent";
import LoginComponent from "./components/LoginComponent";
import SignUpComponent from "./components/SignUpComponent";
import MenuComponent from "./components/MenuComponent";
import MenuDescriptionComponent from "./components/MenuDescriptionComponent";
import CartItemsComponent from "./components/CartItemsComponent";
import EmptyCartComponent from "./components/EmptyCartComponent";
import NavBar from "./components/NavBar/NavBar";
import ReviewsComponent from "./components/ReviewsComponent";
import PaymentDetailsComponent from "./components/PayementDetailsComponent";
import ReservationComponent from "./components/ReservationComponent";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<IndexComponent />} />
        <Route exact path="/contact-us" element={<ContactUsComponent />} />
        <Route exact path="/about-us" element={<AboutUsComponent />} />
        <Route exact path="/reviews" element={<ReviewsComponent />} />
        <Route
          exact
          path="/payment-details/:total"
          element={<PaymentDetailsComponent />}
        />
        <Route
          exact
          path="/view-item/:id"
          element={<MenuDescriptionComponent />}
        />
        <Route exact path="/cart-items" element={<CartItemsComponent />} />
        <Route exact path="/empty-cart" element={<EmptyCartComponent />} />
        <Route exact path="/menu" element={<MenuComponent />} />
        <Route exact path="/reservation" element={<ReservationComponent />} />
        <Route exact path="/login" element={<LoginComponent />} />
        <Route exact path="/sign-up" element={<SignUpComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
