import logo from "../../images/logo_black.png";
import user_logo from "../../images/user/user-5.jpg";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { getCartById } from "../../services/cartService";
import auth from "../../services/authService";
import { getItems } from "../../services/itemService";
function NavBar() {
  let [counter, setCounter] = useState(0);
  const [items, setItems] = useState([]);
  const [default_items, setDefaultItems] = useState([]);
  let user = auth.getCurrentUser();
  let loggedDisplay = "block";
  let accountDisplay = "none";
  if (user) {
    loggedDisplay = "none";
    accountDisplay = "block";
  }
  const logout = () => {
    auth.logout();
    window.location = "/";
  };
  const searchItems = (query) => {
    let filtered = default_items.filter((item) =>
      item.item_name.toLowerCase().startsWith(query.toLowerCase())
    );
    setItems(filtered);
    //  allData.filter((m) =>
    //    m[key].toLowerCase().startsWith(searchQuery.toLowerCase())
    //  );
  };
  useEffect(() => {
    async function getCart() {
      let user = auth.getCurrentUser();
      if (user) {
        let { data } = await getCartById(user.user_id);
        console.log(data.length);
        setCounter(data.length);
      }
    }
    async function loadItems() {
      let { data } = await getItems();
      setItems(data);
      setDefaultItems(data);
    }

    getCart();
    loadItems();
  }, []);
  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <div className="iq-sidebar-logoo">
          <div
            className="top-logo"
            style={{
              display: "inline-block",
              padding: "10px 10px 10px 30px",
              float: "left",
            }}
          >
            <NavLink to="/" className="logo">
              <img
                src={logo}
                style={{ height: "50px" }}
                className="img-fluid"
                alt=""
              />
            </NavLink>
          </div>
        </div>
        <div className="navbar-breadcrumb">
          <h4 className="mt-2">Blacky Restaurant</h4>
          {/* <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Home
              </li>
            </ul>
          </nav> */}
        </div>
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="ri-menu-3-line"></i>
          </button>
          <div className="iq-menu-bt align-self-center">
            <div className="wrapper-menu">
              <div className="line-menu half start"></div>
              <div className="line-menu"></div>
              <div className="line-menu half end"></div>
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto navbar-list">
              <li className="nav-item iq-full-screen">
                <a href="/" className="mt-1 mr-3 c-nav-link">
                  HOME
                </a>
              </li>
              <li className="nav-item iq-full-screen">
                <NavLink to="/menu" className="mt-1 mr-3 c-nav-link">
                  MENU
                </NavLink>
              </li>
              <li className="nav-item iq-full-screen">
                <NavLink to="/reviews" className="mt-1 mr-3 c-nav-link">
                  REVIEWS
                </NavLink>
              </li>
              <li className="nav-item iq-full-screen">
                <NavLink to="/reservation" className="mt-1 mr-3 c-nav-link">
                  RESERVATION
                </NavLink>
              </li>
              {/* <li className="nav-item iq-full-screen">
                <NavLink to="/" className="mt-1 mr-3 c-nav-link">
                  REVIEWS
                </NavLink>
              </li> */}
              <li className="nav-item iq-full-screen">
                <NavLink to="/contact-us" className="mt-1 mr-3 c-nav-link">
                  CONTACT US
                </NavLink>
              </li>
              <li className="nav-item iq-full-screen">
                <NavLink to="/about-us" className="mt-1 mr-3 c-nav-link">
                  ABOUT US
                </NavLink>
              </li>
              <li
                style={{ display: loggedDisplay }}
                className="nav-item iq-full-screen"
              >
                <NavLink to="/sign-up" className="mt-1 mr-3 c-nav-link">
                  SIGN UP
                </NavLink>
              </li>
              <li
                style={{ display: loggedDisplay }}
                className="nav-item iq-full-screen"
              >
                <NavLink to="/login" className="mt-1 mr-3 c-nav-link">
                  LOGIN
                </NavLink>
              </li>
              <li className="nav-item">
                {/* <button
                  type="button"
                  className="search-toggle iq-waves-effect btn btn-outline-default mt-3 ml-3"
                >
                  Search for Items
                </button> */}
                <a className="search-toggle iq-waves-effect" href="#">
                  <i className="ri-search-line"></i>
                </a>
                <form action="#" className="search-box">
                  <input
                    onChange={(e) => searchItems(e.target.value)}
                    type="text"
                    className="text search-input"
                    placeholder="Type here to search..."
                  />
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none m-0">
                    <div className="iq-card-body p-2 mt-1">
                      <div className="iq-sub-card">
                        <div className="media align-items-center">
                          <div
                            className="media-body ml-1"
                            style={{ height: "300px", overflow: "scroll" }}
                          >
                            {items.map((item) => (
                              <NavLink to={"/view-item/" + item.item_id}>
                                <div className="row p-0 m-2">
                                  <div className="col-2">
                                    <img
                                      src={item.item_avatar}
                                      width="50px"
                                      height="50px"
                                      style={{ objectFit: "cover" }}
                                    />
                                  </div>
                                  <div className="col-10">
                                    <h6>{item.item_name}</h6>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "black",
                                      }}
                                    >
                                      &pound; {item.item_price}
                                    </p>
                                  </div>
                                </div>
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </li>
              {/* <li className="nav-item dropdown">
                <a href="#" className="search-toggle iq-waves-effect">
                  <i className="ri-mail-line"></i>
                  <span className="badge badge-pill badge-success badge-up count-mail">
                    3
                  </span>
                </a>
                <div className="iq-sub-dropdown">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-success p-3">
                        <h5 className="mb-0 text-white">
                          All Messages
                          <small className="badge  badge-light float-right pt-1">
                            5
                          </small>
                        </h5>
                      </div>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src="images/user/01.jpg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Nik Emma Watson</h6>
                            <small className="float-left font-size-12">
                              13 Jun
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src="images/user/02.jpg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                            <small className="float-left font-size-12">
                              20 Apr
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src="images/user/03.jpg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Why do we use it?</h6>
                            <small className="float-left font-size-12">
                              30 Jun
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src="images/user/04.jpg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Variations Passages</h6>
                            <small className="float-left font-size-12">
                              12 Sep
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src="images/user/05.jpg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                            <small className="float-left font-size-12">
                              5 Dec
                            </small>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            */}

              <li className="nav-item">
                <NavLink to="/cart-items" className="iq-waves-effect">
                  <i className="ri-shopping-cart-2-line"></i>
                  <span className="badge badge-pill badge-success badge-up count-mail">
                    {counter}
                  </span>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <a href="#" className="search-toggle iq-waves-effect">
                  <i className="ri-notification-2-line"></i>
                  <span className="badge badge-pill badge-success badge-up count-mail">
                    3
                  </span>
                </a>
                <div className="iq-sub-dropdown">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-danger p-3">
                        <h5 className="mb-0 text-white">
                          All Notifications
                          <small className="badge  badge-light float-right pt-1">
                            4
                          </small>
                        </h5>
                      </div>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">New Order Recieved</h6>
                            <small className="float-right font-size-12">
                              23 hrs ago
                            </small>
                            <p className="mb-0">Lorem is simply</p>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src="images/user/01.jpg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Emma Watson Nik</h6>
                            <small className="float-right font-size-12">
                              Just Now
                            </small>
                            <p className="mb-0">95 MB</p>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src="images/user/02.jpg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">New customer is join</h6>
                            <small className="float-right font-size-12">
                              5 days ago
                            </small>
                            <p className="mb-0">Jond Nik</p>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40"
                              src="images/small/jpg.svg"
                              alt=""
                            />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Updates Available</h6>
                            <small className="float-right font-size-12">
                              Just Now
                            </small>
                            <p className="mb-0">120 MB</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li> */}
              {/* <li className="nav-item iq-full-screen">
                <a href="#" className="iq-waves-effect" id="btnFullscreen">
                  <i className="ri-fullscreen-line"></i>
                </a>
              </li> */}
            </ul>
          </div>
          <ul style={{ display: accountDisplay }} className="navbar-list">
            <li>
              <a href="#" className="search-toggle iq-waves-effect  text-dark">
                <img src={user_logo} className="img-fluid rounded" alt="user" />
              </a>
              <div className="iq-sub-dropdown iq-user-dropdown">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none m-0">
                  <div className="iq-card-body p-0 ">
                    <div className="bg-success p-3">
                      <h5 className="mb-0 text-white line-height">
                        {user && <span>Hello {user.fullname}</span>}
                      </h5>
                      <span className="text-white font-size-12">Available</span>
                    </div>
                    {/* <a
                      href="profile.html"
                      className="iq-sub-card iq-bg-success-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-success">
                          <i className="ri-file-user-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0 ">My Profile</h6>
                          <p className="mb-0 font-size-12">
                            View personal profile details.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="profile-edit.html"
                      className="iq-sub-card iq-bg-success-success-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-success">
                          <i className="ri-profile-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0 ">Edit Profile</h6>
                          <p className="mb-0 font-size-12">
                            Modify your personal details.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="account-setting.html"
                      className="iq-sub-card iq-bg-success-danger-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-danger">
                          <i className="ri-account-box-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0 ">Account settings</h6>
                          <p className="mb-0 font-size-12">
                            Manage your account parameters.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="privacy-setting.html"
                      className="iq-sub-card iq-bg-success-secondary-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-secondary">
                          <i className="ri-lock-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0 ">Privacy Settings</h6>
                          <p className="mb-0 font-size-12">
                            Control your privacy parameters.
                          </p>
                        </div>
                      </div>
                    </a> */}
                    <div className="d-inline-block w-100 text-center p-3">
                      <a
                        onClick={logout}
                        className="iq-bg-danger iq-sign-btn"
                        href="#"
                        role="button"
                      >
                        Sign out<i className="ri-login-box-line ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
export default NavBar;
