import Notiflix from "notiflix";
import React from "react";
//import logo from "./logo.svg";
import { PaystackButton } from "react-paystack";
import { sendOrder } from "../services/emailService";
//import "./App.css";
import auth from "../services/authService";
import { addToOrder } from "../services/orderService";
function PaystackComponent(props) {
  let user = auth.getCurrentUser();
  let { id, phone, code, address, town, country, customer, total, quantity } =
    props;
  //   let data = {
  //     item_id: id,
  //     user_id: user.user_id,
  //     address: address + " " + code + " " + town + " " + country,
  //     quantity: quantity,
  //     amount: Number(total),
  //     status: 1,
  //     tracking: "",
  //   };
  //   // console.log(data);
  //   try {
  //     addToOrder(data);
  //     // let dty = {
  //     //   email: user.email,
  //     //   user: customer,
  //     //   quantity: quantity,
  //     //   tracking: "",
  //     // };
  //     // sendOrder(dty);
  //   } catch (error) {
  //     Notiflix.Notify.failure("An Error Has Occurred");
  //   }

  const saveOrderAndEmail = async () => {
    let data = {
      item_id: id,
      user_id: user.user_id,
      address: address + " " + code + " " + town + " " + country,
      quantity: quantity,
      amount: Number(total),
      status: 1,
      tracking: "5rytrrt",
    };
    console.log(data);
    try {
      await addToOrder(data);
      let dty = {
        email: user.email,
        user: customer,
        quantity: quantity,
        tracking: "",
        amount: Number(total),
      };
      await sendOrder(dty);
    } catch (error) {
      Notiflix.Notify.failure("An Error Has Occurred");
    }
  };
  const config = {
    reference: new Date().getTime().toString(),
    email: user.email,
    amount: props.total * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_test_c1e3f63c5adbe366c06f22a25ea8fd2e12e05755",
  };
  // alert(props.total);
  // you can call this function anything
  const handlePaystackSuccessAction = (reference) => {
    if (reference.status == "success") {
      Notiflix.Report.success(
        "Success",
        "Your Payment was Successfull An Email with Order status has been sent to you",
        "OK"
      );
      saveOrderAndEmail();
    } else {
      Notiflix.Report.failure(
        "Success",
        "Your Payment was not Successfull",
        "RETRY"
      );
    }
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Make Payment",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <div className="App">
      <header className="App-header" style={{ display: "none" }}>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <PaystackButton {...componentProps} className="btn btn-success btn-lg" />
    </div>
  );
}

export default PaystackComponent;
