import React from "react";
import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import OwlCarousel from "react-owl-carousel";
import DemoCarousel from "./Common/DemoCarousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import img1 from "../images/page-img/03.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import { getItems } from "../services/itemService";
import { NavLink, useParams } from "react-router-dom";
import PaystackComponent from "./PaystackComponent";
import Notiflix from "notiflix";
import auth from "../services/authService";
import { getCartById, deleteCartById } from "../services/cartService";
function PaymentDetailsComponent() {
  let user = auth.getCurrentUser();
  let { total, id, qty } = useParams();

  const [items, setItems] = useState([]);
  const [item_id, setItemId] = useState(0);
  const [customer, setCustomer] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [country, setCountry] = useState("");
  const [subtotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    async function loadItems() {
      let totalPrice = 0;
      let qty = 0;
      if (user) {
        let { data } = await getCartById(user.user_id);
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          totalPrice += element.item_price * element.quantity;
          qty += element.quantity;
        }
        console.log(data);
        setSubTotal(totalPrice);
        setQuantity(qty);
        setItems(data);

        setItemId(data[0].item_id);
      }
    }
    loadItems();
  }, []);
  const setData = (object) => {
    console.log(object.id);
    switch (object.name) {
      case "customer":
        setCustomer(object.value);
        break;
      case "phone":
        setPhone(object.value);
        break;
      case "code":
        setCode(object.value);
        break;
      case "address":
        setAddress(object.value);
        break;
      case "town":
        setTown(object.value);
        break;
      case "country":
        setCountry(object.value);
        break;

      default:
        break;
    }
    if (customer.length > 0) {
      document.getElementById("payBlock").style.visibility = "visible";
    } else {
      //Notiflix.Notify.failure("Missing Required fields");
    }
  };

  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div id="content-page" style={{ marginTop: "90px" }}>
          <div className="container-fluid p-5">
            <h4> 1. Delivery Address </h4>
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Customer Address</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <form>
                  <div className="row">
                    <div className="col-6">
                      <div class="form-group">
                        <label for="exampleInputText1">Customer Name </label>
                        <input
                          value={customer}
                          onChange={(e) => setData(e.target)}
                          type="text"
                          class="form-control"
                          name="customer"
                          id="exampleInputText1"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-group">
                        <label for="exampleInputEmail3">Phone Number</label>
                        <input
                          value={phone}
                          onChange={(e) => setData(e.target)}
                          name="phone"
                          type="tel"
                          class="form-control"
                          id="exampleInputEmail3"
                          placeholder="Enter Phone"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div class="form-group">
                        <label for="exampleInputEmail3">Post Code</label>
                        <input
                          value={code}
                          onChange={(e) => setData(e.target)}
                          name="code"
                          type="text"
                          class="form-control"
                          id="exampleInputEmail3"
                          placeholder="Enter Post Code"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-group">
                        <label for="exampleInputEmail3">Address</label>
                        <input
                          value={address}
                          onChange={(e) => setData(e.target)}
                          name="address"
                          type="text"
                          class="form-control"
                          id="exampleInputEmail3"
                          placeholder="Enter Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div class="form-group">
                        <label for="exampleInputEmail3">Town</label>
                        <input
                          value={town}
                          onChange={(e) => setData(e.target)}
                          name="town"
                          type="text"
                          class="form-control"
                          id="exampleInputEmail3"
                          placeholder="Enter Town"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-group">
                        <label for="exampleInputEmail3">Country</label>
                        <input
                          value={country}
                          onChange={(e) => setData(e.target)}
                          name="country"
                          type="text"
                          class="form-control"
                          id="exampleInputEmail3"
                          placeholder="Enter Country"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <h4> 2. Payment Option </h4>
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Select Payment Option</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <img
                  width="200px"
                  src="https://png.pngtree.com/png-vector/20190130/ourlarge/pngtree-credit-card-commercial-element-cardfinancial-elementai-illustration-png-image_595759.jpg"
                />
              </div>
            </div>
            <h4> 3. Add Voucher Code </h4>
            <div class="iq-card">
              <div class="iq-card-body">
                <div className="col-6">
                  <div class="form-group">
                    <label for="exampleInputText1">Voucher Code </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputText1"
                      placeholder="Enter Code"
                    />
                  </div>
                  <div id="payBlock" style={{ visibility: "hidden" }}>
                    <PaystackComponent
                      id={item_id}
                      phone={phone}
                      code={code}
                      address={address}
                      town={town}
                      country={country}
                      customer={customer}
                      total={total}
                      quantity={quantity}
                    />
                    ;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default PaymentDetailsComponent;
