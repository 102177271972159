import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/03.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Notiflix from "notiflix";
import { login } from "../services/authService";
function LoginComponent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const doSubmit = async () => {
    if (email.length <= 0) {
      Notiflix.Notify.failure("Email is Required !!!!!");
      return;
    } else if (password.length <= 0) {
      Notiflix.Notify.failure("Password is Required !!!!!");
      return;
    } else {
      try {
        await login(email, password);
        window.location = "/";
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          Notiflix.Notify.failure(ex.response.data);
        }
      }
    }
  };
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div id="content-page" style={{ marginTop: "80px" }}>
          <section class="sign-in-page bg-white" style={{ height: "90vh" }}>
            <div class="container-fluid pt-3">
              <div class="row no-gutters">
                <div class="col-sm-6 align-self-center">
                  <div class="sign-in-from">
                    <h4 class="mb-0">Sign in</h4>
                    <p>Enter your email address and password</p>
                    <form class="mt-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          class="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        {/* <a href="#" class="float-right text-success">
                          Forgot password?
                        </a> */}
                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          class="form-control mb-0"
                          id="exampleInputPassword1"
                          placeholder="Password"
                        />
                      </div>
                      <div class="d-inline-block w-100">
                        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          >
                            Accept Terms and Conditions
                          </label>
                        </div>
                        <button
                          onClick={doSubmit}
                          type="button"
                          class="btn btn-success float-right"
                        >
                          Sign in
                        </button>
                      </div>
                      <div class="sign-info">
                        <span class="dark-color  d-inline-block line-height-2">
                          Don't have an account?{" "}
                          <NavLink to="/sign-up" className="text-success">
                            Sign up
                          </NavLink>
                        </span>
                        {/* <ul class="iq-social-media">
                          <li>
                            <a href="#">
                              <i class="ri-facebook-box-line"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="ri-twitter-line"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="ri-instagram-line"></i>
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-sm-6 text-center">
                  <img
                    src="https://images.pexels.com/photos/1378424/pexels-photo-1378424.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    class="img-fluid"
                    style={{
                      height: "600px",
                      width: "100vw",
                      objectFit: "cover",
                    }}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default LoginComponent;
