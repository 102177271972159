import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/03.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
function AboutUsComponent() {
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div id="content-page" style={{ marginTop: "90px" }}>
          <div className="container-fluid">
            <div
              className="card"
              style={{ height: "500px", overflow: "hidden" }}
            >
              <img
                src="https://images.pexels.com/photos/1410236/pexels-photo-1410236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                class="img-fluid"
                alt=""
                style={{ height: "500px", objectFit: "cover" }}
              />
              <h2 class="text-white">Congratulations Nik</h2>
              <p class="text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. remaining essentially unchanged.
              </p>
              <button type="button" class="btn iq-bg-primary">
                Read More
              </button>
            </div>
            <div class="row mt-5 mb-5 gap-5">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h5 className="text-center">ABOUT US</h5>
                <div className="cardd">
                  <h4 className="text-center">
                    Blacky restaurant started from cooking passion of an
                    individual who delights in cooking traditional African
                    cuisine with a touch of intercontinental taste. It has a
                    humble beginning as a small restaurant in the City of
                    London, United Kingdom and quickly became the toast of good
                    diners who daily visited the restaurant to savour our menu.
                    Ours is a home away from home as our menu cuts across
                    traditional African food with ingredients and spices sourced
                    from West Africa to continental and oriental menu. Our
                    sumptuous meals are produced under a stringent hygienic and
                    beautiful environment that creates a good ambience for all.
                    Our ‘DAILY SPECIAL’ has become the toast of our customers
                    who are always desirous of having a taste of it. The vision
                    is to continue to be the toast of diners as we plan to open
                    branches in other cities in the United Kingdom in months to
                    come and later to other countries.
                  </h4>
                </div>
              </div>
              <div className="col-1"></div>
              <div class="col-md-6 col-lg-5 col-sm-12">
                <div className="card mt-5">
                  <img
                    style={{ height: "400px", objectFit: "cover" }}
                    src={imgmap}
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <h5 className="text-center">
                  Monday-Thursday 1pm-12am <br />
                  Friday-Saturday 1pm-4am
                  <br />
                  Sunday 1pm-12am
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default AboutUsComponent;
