import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/09.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import { getItems } from "../services/itemService";
import { NavLink, useParams } from "react-router-dom";
import PaystackComponent from "./PaystackComponent";
import Notiflix from "notiflix";
import auth from "../services/authService";
import { getCartById, deleteCartById } from "../services/cartService";
function ReservationComponent() {
  let user = auth.getCurrentUser();
  let { total, id, qty } = useParams();

  const [items, setItems] = useState([]);
  const [item_id, setItemId] = useState(0);
  const [customer, setCustomer] = useState("");
  const [phone, setPhone] = useState("");
  const [guest, setGuest] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [rtype, setRType] = useState("");
  const [subtotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const setData = (object) => {
    console.log(object.id);
    switch (object.name) {
      case "customer":
        setCustomer(object.value);
        break;
      case "phone":
        setPhone(object.value);
        break;
      case "guest":
        setGuest(object.value);
        break;
      case "email":
        setEmail(object.value);
        break;
      case "date":
        setDate(object.value);
        break;
      case "reservation":
        setRType(object.value);
        break;

      default:
        break;
    }
  };
  const bookReservation = () => {
    if (customer.length <= 0) {
      Notiflix.Notify.failure(`Your Name cannot be empty`);
    } else if (email.length <= 0) {
      Notiflix.Notify.failure(`Your Email cannot be empty`);
    } else if (phone.length <= 0) {
      Notiflix.Notify.failure(`Your Phone cannot be empty`);
    } else if (guest.length <= 0) {
      Notiflix.Notify.failure(`Number of Guest cannot be empty`);
    } else if (date.length <= 0) {
      Notiflix.Notify.failure(`Date cannot be empty`);
    } else if (rtype.length <= 0) {
      Notiflix.Notify.failure(`Select Reservation Type`);
    } else {
      Notiflix.Report.success(
        "Response",
        `Your Reservation  has been Received Successfully`,
        "OK"
      );
    }
  };
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div id="content-page" style={{ marginTop: "90px" }}>
          <div className="container-fluid p-5">
            <h4 className="mb-2"> Make a Reservation </h4>
            <div className="row">
              <div className="col-12">
                <div class="iq-card">
                  <div class="iq-card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div class="form-group">
                            <label for="exampleInputText1">Full Name </label>
                            <input
                              value={customer}
                              onChange={(e) => setData(e.target)}
                              type="text"
                              class="form-control"
                              name="customer"
                              id="exampleInputText1"
                              placeholder="Enter Name"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-group">
                            <label for="exampleInputText1">Email </label>
                            <input
                              onChange={(e) => setData(e.target)}
                              type="text"
                              class="form-control"
                              name="email"
                              id="exampleInputText1"
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-group">
                            <label for="exampleInputEmail3">Phone Number</label>
                            <input
                              value={phone}
                              onChange={(e) => setData(e.target)}
                              name="phone"
                              type="tel"
                              class="form-control"
                              id="exampleInputEmail3"
                              placeholder="Enter Phone"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div class="form-group">
                            <label for="exampleInputEmail3">
                              Number of Guest
                            </label>
                            <input
                              onChange={(e) => setData(e.target)}
                              name="guest"
                              type="number"
                              class="form-control"
                              id="exampleInputEmail3"
                              placeholder="Number of Guest"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-group">
                            <label for="exampleInputdatetime">
                              Date and Time Input
                            </label>
                            <input
                              onChange={(e) => setData(e.target)}
                              name="date"
                              type="datetime-local"
                              class="form-control"
                              id="exampleInputdatetime"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-group">
                            <label for="exampleInputEmail3">
                              Reservation type
                            </label>
                            <select
                              name="reservation"
                              class="form-control"
                              onChange={(e) => setData(e.target)}
                            >
                              <option value="">Select Reservation Type</option>
                              <option value="Birthday">Birthday</option>
                              <option value="Anniversaries">
                                Anniversaries
                              </option>
                              <option value="Others">Others</option>
                              <option value="Team Bonding">Team Bonding</option>
                            </select>
                            {/* <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customCheck1"
                              />
                              <label
                                class="custom-control-label"
                                for="customCheck1"
                              >
                                Birthday
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customCheck2"
                              />
                              <label
                                class="custom-control-label"
                                for="customCheck2"
                              >
                                Anniversaries
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customCheck3"
                              />
                              <label
                                class="custom-control-label"
                                for="customCheck3"
                              >
                                Others
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customCheck4"
                              />
                              <label
                                class="custom-control-label"
                                for="customCheck4"
                              >
                                Team Bonding
                              </label>
                            </div> */}
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => bookReservation()}
                            className="btn btn-lg btn-success m-3"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ReservationComponent;
