import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/09.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import { getItems, getItemsById } from "../services/itemService";
import { NavLink, useParams } from "react-router-dom";
import Notiflix from "notiflix";
import auth from "../services/authService";

import { getCartById, deleteCartById } from "../services/cartService";

function EmptyCartComponent(props) {
  let { id } = useParams();
  let user = auth.getCurrentUser();
  let cartItems = [];

  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div
          id="content-page"
          style={{ marginTop: "90px", minHeight: "650px" }}
        >
          <div className="container-fluid">
            <div class="row">
              <div class="mx-auto col-sm-6">
                <div class="card iq-mb-3">
                  <div class="card-body">
                    <h4 class="card-title">There are no Items in your cart</h4>
                    <p class="card-text">Add Items to you cart </p>
                    <a href="/" class="btn btn-primary btn-block">
                      Go Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default EmptyCartComponent;
