import http from "./httpService";
import config from "./config";
const apiEndpoint = config.apiUrl + "/users";
export function register(user) {
  return http.post(apiEndpoint, {
    fullname: user.fullname,
    email: user.email,
    phone_no: user.phone_no,
    password: user.password,
  });
}
export function updateUsers(user) {
  return http.put(apiEndpoint + "/" + user.user_id, {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_no: user.phone_no,
    password: user.password,
    role_id: user.role_id,
    signature_url: user.signature_url,
  });
}
function userUrl(id) {
  return `${apiEndpoint}/${id}`;
}
function userEmailUrl(email) {
  return `${apiEndpoint}/email/${email}`;
}
export function getUsersByEmail(userEmail) {
  let url = config.clientUrl + "change-password";
  return http.post(userEmailUrl(userEmail), {
    url: url,
  });
}

export function getUsersById(userId) {
  return http.get(userUrl(userId));
}
export function getUsers() {
  return http.get(apiEndpoint);
}
export function deleteUsersById(userId) {
  return http.delete(userUrl(userId));
}

export function activateUsersById(userId, code) {
  return http.put(`${apiEndpoint}/activate/${userId}`, {
    activate: code,
  });
}

export function updateUsersPasswordById(userId, password) {
  return http.put(`${apiEndpoint}/update-password/${userId}`, {
    password: password,
  });
}

export default {
  register,
  getUsers,
  getUsersById,
  getUsersByEmail,
  deleteUsersById,
  updateUsersPasswordById,
};
