import http from "./httpService";
import config from "./config";
const apiEndpoint = config.apiUrl + "/cart";
export function addToCart(user) {
  return http.post(apiEndpoint, {
    item_id: user.item_id,
    user_id: user.user_id,
    quantity: user.quantity,
    amount: user.amount,
    status: user.status,
  });
}

function userUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCartById(userId) {
  return http.get(userUrl(userId));
}
export function getCarts() {
  return http.get(apiEndpoint);
}

export function deleteCartById(userId) {
  return http.delete(userUrl(userId));
}

export default {
  addToCart,
  getCartById,
  deleteCartById,
};
