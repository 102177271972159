import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/09.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import { getItems } from "../services/itemService";
import { NavLink } from "react-router-dom";
function MenuComponent() {
  const [items, setItems] = useState([]);
  const [default_items, setDefaultItems] = useState([]);
  useEffect(() => {
    async function loadItems() {
      let { data } = await getItems();
      setItems(data);
      setDefaultItems(data);
    }
    loadItems();
  }, []);
  const reloadData = async () => {
    let { data } = await getItems();
    setItems(data);
  };
  const filterData = (e) => {
    let filtered = default_items.filter(
      (item) => item.item_category.toLowerCase() == e.toLowerCase()
    );
    setItems(filtered);
  };
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div
          id="content-page"
          style={{ marginTop: "90px", minHeight: "650px" }}
        >
          <div className="container-fluid">
            <br />
            <div className="text-center m-2">
              <div
                class="btn-group mb-3"
                role="group"
                aria-label="Basic example"
              >
                <button
                  onClick={reloadData}
                  type="button"
                  class="btn btn-outline-success"
                >
                  All Items
                </button>
                <button
                  onClick={() => filterData("soup")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  Soup
                </button>
                <button
                  onClick={() => filterData("mains")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  Mains
                </button>
                <button
                  onClick={() => filterData("grills")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  Grills
                </button>
              </div>
            </div>
            {/* <h3 className="text-center m-3">OUR MENU</h3> */}
            <div class="row m-3">
              {items.map((item) => (
                <NavLink to={"/view-item/" + item.item_id} className="col-sm-3">
                  <div key={item.item_id} class="item">
                    <div class="card iq-mb-3">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src={item.item_avatar}
                        class="card-img-top"
                        alt="#"
                      />
                      <div class="card-body">
                        <h4 class="card-title">
                          {item.item_name}
                          <br />
                          &pound; {item.item_price}
                        </h4>
                        <p
                          class="card-text text-muted"
                          style={{ height: "100px", overflow: "hidden" }}
                        >
                          {item.item_desc}
                        </p>
                        <p class="card-text">
                          <small class="text-muted">{item.item_category}</small>
                          <button
                            style={{ float: "right" }}
                            className="btn btn-sm btn-success"
                          >
                            VIEW ITEM
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default MenuComponent;
