import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/03.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Notiflix from "notiflix";
import { register } from "../services/userService";
import { login } from "../services/authService";
function SignUpComponent() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  function ValidateEmail(email) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      //alert("Valid email address!");
      return true;
    } else {
      // alert("Invalid email address!");
      return false;
    }
  }
  const CheckPassword = (password) => {
    var passw = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (password.match(passw)) {
      // alert('Correct, try another...')
      return true;
    } else {
      // alert('Wrong...!')
      return false;
    }
  };
  const doSubmit = async () => {
    if (fullname.length <= 0) {
      Notiflix.Notify.failure("FullName is Required !!!!!");
      return;
    } else if (email.length <= 0) {
      Notiflix.Notify.failure("Email is Required !!!!!");
      return;
    } else if (ValidateEmail(email) !== true) {
      Notiflix.Notify.failure("Email is not Valid !!!!!");
    } else if (phone.length <= 0) {
      Notiflix.Notify.failure("Phone No is Required !!!!!");
      return;
    } else if (CheckPassword(password) !== true) {
      Notiflix.Notify.failure(
        "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter  !!!!!"
      );
      return;
    } else if (password != confirm) {
      Notiflix.Notify.failure("Passwords do not Match !!!!!");
      return;
    } else {
      try {
        let user = {
          fullname: fullname,
          email: email,
          phone_no: phone,
          password: password,
        };
        let response = await register(user);
        Notiflix.Notify.success("Account Created Successfully");
        await login(email, password);
        window.location = "/";
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          Notiflix.Notify.failure(ex.response.data);
        }
      }
    }
  };
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div
          id="content-page"
          style={{ marginTop: "80px", marginBottom: "40px" }}
        >
          <section class="sign-in-page bg-white" style={{ height: "100vh" }}>
            <div class="container-fluid pt-3">
              <div class="row no-gutters">
                <div class="col-sm-6 align-self-center">
                  <div class="sign-in-from">
                    <h4 class="mb-0">New Here ? </h4>
                    <p>
                      sign up and discover a great amount of discounts and new
                      opportunity Existing user login here Login Blacky
                      restaurants offers
                    </p>
                    <form class="mt-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Full Name</label>
                        <input
                          onChange={(e) => setFullname(e.target.value)}
                          type="text"
                          class="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="Enter FullName"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          class="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Phone</label>

                        <input
                          onChange={(e) => setPhone(e.target.value)}
                          type="number"
                          class="form-control mb-0"
                          id="exampleInputPassword1"
                          placeholder="Enter Phone"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>

                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          class="form-control mb-0"
                          id="exampleInputPassword1"
                          placeholder="Password"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">
                          Confirm Password
                        </label>

                        <input
                          onChange={(e) => setConfirm(e.target.value)}
                          type="password"
                          class="form-control mb-0"
                          id="exampleInputPassword1"
                          placeholder="Confirm Password"
                        />
                      </div>
                      <div class="d-inline-block w-100">
                        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          >
                            Remember Me
                          </label>
                        </div>
                        <button
                          onClick={doSubmit}
                          type="button"
                          class="btn btn-success float-right"
                        >
                          Sign Up
                        </button>
                      </div>
                      <div class="sign-info">
                        <span class="dark-color d-inline-block line-height-2">
                          Have an account? <NavLink to="/login">Login</NavLink>
                        </span>
                        {/* <ul class="iq-social-media">
                          <li>
                            <a href="#">
                              <i class="ri-facebook-box-line"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="ri-twitter-line"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="ri-instagram-line"></i>
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-sm-6 text-center">
                  <img
                    src="https://images.pexels.com/photos/1378424/pexels-photo-1378424.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    class="img-fluid"
                    style={{
                      height: "600px",
                      width: "100vw",
                      objectFit: "cover",
                    }}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default SignUpComponent;
