function Footer() {
  return (
    <footer className="bg-white iq-footer" style={{ margin: "0px" }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a href="privacy-policy.html">Privacy Policy</a>
              </li>
              <li className="list-inline-item">
                <a href="terms-of-service.html">Terms of Use</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 text-right">
            Copyright 2023 <a href="#">Blacky Restaurant</a> All Rights
            Reserved.
            <i class="fa fa-facebook m-2"></i>
            <i class="fa fa-twitter m-2"></i>
            <i class="fa fa-instagram m-2"></i>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
