import logo from "../logo.svg";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/09.jpg";
import imgmap from "../images/map.webp";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import { useEffect, useState } from "react";
import { getItems, getItemsById } from "../services/itemService";
import { NavLink, useParams } from "react-router-dom";
import Notiflix from "notiflix";
import auth from "../services/authService";
import { addToCart } from "../services/cartService";
function MenuDescriptionComponent(props) {
  let { id } = useParams();
  let user = auth.getCurrentUser();
  const [item_name, setItemName] = useState("");
  const [item_avatar, setItemAvatar] = useState("");
  const [item_category, setItemCategory] = useState("");
  const [item_desc, setItemDesc] = useState("");
  const [item_price, setItemPrice] = useState("");
  const [item_quantity, setItemQuantity] = useState(1);
  useEffect(() => {
    async function loadItems() {
      let { data } = await getItemsById(id);
      setItemName(data[0].item_name);
      setItemAvatar(data[0].item_avatar);
      setItemCategory(data[0].item_category);
      setItemDesc(data[0].item_desc);
      setItemPrice(data[0].item_price);
    }
    loadItems();
  }, []);
  const addCart = async () => {
    if (!user) {
      //   Notiflix.Notify.failure("Email is Required !!!!!");
      Notiflix.Notify.failure("Please Login to Add Item to Cart");
      return;
    } else {
      let cartObj = {
        item_id: id,
        user_id: user.user_id,
        quantity: item_quantity,
        amount: item_price * item_quantity,
        status: 1,
      };
      try {
        let { data } = await addToCart(cartObj);
        console.log(data);
        Notiflix.Report.success(
          "Response",
          "Item Added to Cart Successfully",
          "OK"
        );
        window.location.reload();
      } catch (error) {
        Notiflix.Notify.failure("Item could not be Added");
      }
    }
  };

  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div
          id="content-page"
          style={{ marginTop: "90px", minHeight: "650px" }}
        >
          <div className="container-fluid">
            <br />
            <div className="text-center m-2">
              {/* <div
                class="btn-group mb-3"
                role="group"
                aria-label="Basic example"
              >
                <button
                  onClick={reloadData}
                  type="button"
                  class="btn btn-outline-success"
                >
                  All Items
                </button>
                <button
                  onClick={() => filterData("Continental Dish")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  Continental Dish
                </button>
                <button
                  onClick={() => filterData("African Dish")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  African Dish
                </button>
                <button
                  onClick={() => filterData("Oriental Dish")}
                  type="button"
                  class="btn btn-outline-success"
                >
                  Oriental Dish
                </button>
              </div> */}
            </div>
            {/* <h3 className="text-center m-3">OUR MENU</h3> */}
            <div class="row m-3">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <img
                  style={{ height: "400px", width: "100%", objectFit: "cover" }}
                  src={item_avatar}
                  className="mb-3"
                />
                <h3>
                  {item_name}{" "}
                  <button
                    style={{ float: "right" }}
                    className="btn btn-sm btn-success"
                  >
                    {item_category}
                  </button>
                </h3>
                <h5 className="text-bold m-2"> &pound; {item_price}</h5>

                <p style={{ textAlign: "justify" }}>{item_desc}</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form class="form-horizontal" action="/action_page.php">
                  <div class="form-group row">
                    <label
                      class="control-label col-sm-2 align-self-center mb-0"
                      for="email"
                    >
                      Quantity:
                    </label>
                    <div class="col-sm-10">
                      <input
                        value={item_quantity}
                        onChange={(e) => setItemQuantity(e.target.value)}
                        min="1"
                        type="number"
                        class="form-control"
                        id="email"
                        placeholder="Quantity"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="control-label col-sm-2 align-self-center mb-0"
                      for="email"
                    >
                      Subtotal:
                    </label>
                    <div class="col-sm-10">
                      <label> &pound; {item_price * item_quantity}</label>
                    </div>
                  </div>

                  <div class="form-group">
                    <button
                      onClick={addCart}
                      style={{ float: "right" }}
                      type="button"
                      class="btn btn-primary m-1"
                    >
                      Add to Cart
                    </button>
                    {/* <button
                      style={{ float: "right" }}
                      type="button"
                      class="btn btn-success m-1"
                    >
                      Make Payment
                    </button> */}
                  </div>
                </form>
              </div>
              {/* {items.map((item) => (
                <NavLink to={"/view-item/" + item.item_id} className="col-sm-3">
                  <div key={item.item_id} class="item">
                    <div class="card iq-mb-3">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src={item.item_avatar}
                        class="card-img-top"
                        alt="#"
                      />
                      <div class="card-body">
                        <h4 class="card-title">
                          {item.item_name}
                          <br />
                          &pound; {item.item_price}
                        </h4>
                        <p class="card-text">{item.item_desc}</p>
                        <p class="card-text">
                          <small class="text-muted">{item.item_category}</small>
                          <button
                            style={{ float: "right" }}
                            className="btn btn-sm btn-success"
                          >
                            VIEW ITEM
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ))} */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default MenuDescriptionComponent;
