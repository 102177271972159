import logo from "../logo.svg";
import mapimg from "../map.png";
import "../App.css";
import "../css/bootstrap.min.css";
import "../css/typography.css";
import "../css/style.css";
import "../css/responsive.css";
import img1 from "../images/page-img/03.jpg";
import imgmap from "../ukmap.png";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import Notiflix from "notiflix";
import { useState, useEffect } from "react";
function ContactUsComponent() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  function ValidateEmail(email) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      //alert("Valid email address!");
      return true;
    } else {
      // alert("Invalid email address!");
      return false;
    }
  }
  const CheckPassword = () => {
    var passw = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (email.match(passw)) {
      // alert('Correct, try another...')
      return true;
    } else {
      // alert('Wrong...!')
      return false;
    }
  };
  const doSubmit = () => {
    if (firstname.length <= 0) {
      Notiflix.Notify.failure("FirstName is Required !!!!!");
      return;
    } else if (lastname.length <= 0) {
      Notiflix.Notify.failure("LastName is Required !!!!!");
      return;
    } else if (email.length <= 0) {
      Notiflix.Notify.failure("Email is Required !!!!!");
      return;
    } else if (ValidateEmail(email) !== true) {
      Notiflix.Notify.failure("Email is  not valid !!!!!");
      return;
    } else if (phone.length <= 0) {
      Notiflix.Notify.failure("Phone No is Required !!!!!");
      return;
    } else {
      try {
        // let user = {
        //   fullname: fullname,
        //   email: email,
        //   phone_no: phone,
        //   password: password,
        // };
        // let response = await register(user);
        Notiflix.Report.success("Response", "THANK YOU MESSAGE RECEIVED", "OK");
        // await login(email, password);
        // window.location = "/";
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          Notiflix.Notify.failure(ex.response.data);
        }
      }
    }
  };
  return (
    <div>
      <div className="wrapper">
        <NavBar />
        <div id="content-page" style={{ marginTop: "90px" }}>
          <div className="container-fluid">
            <div
              className="card"
              style={{ height: "500px", overflow: "hidden" }}
            >
              <img
                src="https://images.pexels.com/photos/1410236/pexels-photo-1410236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                class="img-fluid"
                alt=""
                style={{ height: "500px", objectFit: "cover" }}
              />
              <h2 class="text-white">Congratulations Nik</h2>
              <p class="text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. remaining essentially unchanged.
              </p>
              <button type="button" class="btn iq-bg-primary">
                Read More
              </button>
            </div>
            <div class="row mt-5 mb-5 gap-5">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">CONTACT US</h4>
                    </div>
                  </div>
                  <div className="iq-card-body">
                    <p>Feel free to Contact Us</p>
                    <form className="form-horizontal" action="/action_page.php">
                      <div className="form-group row">
                        <label
                          className="control-label col-sm-2 align-self-center mb-0"
                          for="email"
                        >
                          FirstName:
                        </label>
                        <div className="col-sm-10">
                          <input
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Enter Your  First Name"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="control-label col-sm-2 align-self-center mb-0"
                          for="email"
                        >
                          LastName:
                        </label>
                        <div className="col-sm-10">
                          <input
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Enter Your  Last Name"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="control-label col-sm-2 align-self-center mb-0"
                          for="email"
                        >
                          Email:
                        </label>
                        <div className="col-sm-10">
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter Your  email"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="control-label col-sm-2 align-self-center mb-0"
                          for="pwd1"
                        >
                          Phone No:
                        </label>
                        <div className="col-sm-10">
                          <input
                            onChange={(e) => setPhone(e.target.value)}
                            type="tel"
                            className="form-control"
                            id="pwd1"
                            placeholder="Enter Your Phone No"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          className="control-label col-sm-2 align-self-center mb-0"
                          for="pwd1"
                        >
                          Message
                        </label>
                        <div className="col-sm-10">
                          <textarea
                            onChange={(e) => setMessage(e.target.value)}
                            rows="4"
                            className="form-control"
                            placeholder="Enter Your Message"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="button"
                          onClick={doSubmit}
                          className="btn btn-primary mr-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div class="col-md-6 col-lg-5 col-sm-12">
                <div className="card">
                  <img
                    style={{ height: "400px", objectFit: "cover" }}
                    src={imgmap}
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <h5 className="text-center">
                  Monday-Thursday 1pm-12am <br />
                  Friday-Saturday 1pm-4am
                  <br />
                  Sunday 1pm-12am
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ContactUsComponent;
