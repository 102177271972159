import http from "./httpService";
import config from "./config";
const apiEndpoint = config.apiUrl + "/order";
export function addToOrder(user) {
  console.log(user);
  return http.post(apiEndpoint, {
    address: user.address,
    item_id: user.item_id,
    user_id: user.user_id,
    quantity: user.quantity,
    amount: Number(user.amount),
    status: 1,
    tracking: user.tracking,
  });
}

function userUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCartById(userId) {
  return http.get(userUrl(userId));
}
export function getCarts() {
  return http.get(apiEndpoint);
}

export function deleteCartById(userId) {
  return http.delete(userUrl(userId));
}

export default {
  addToOrder,
  getCartById,
  deleteCartById,
};
