import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useEffect, useState } from "react";
import { getItems } from "../../services/itemService";
import { Carousel } from "react-responsive-carousel";
import { NavLink } from "react-router-dom";
function DemoCarousel() {
  const [items, setItems] = useState([]);
  useEffect(() => {
    async function loadItems() {
      let { data } = await getItems();
      setItems(data);
    }
    loadItems();
  }, []);
  return (
    items.length > 0 && (
      <Carousel autoPlay={true}>
        {items.map((item) => (
          <div>
            <NavLink to={"/view-item/" + item.item_id}>
              <img
                style={{ height: "400px", objectFit: "cover" }}
                src={item.item_avatar}
                class="card-img-top"
                alt="#"
              />
              <p style={{ backgroundColor: "green" }} className="legend">
                {" "}
                {item.item_name}
              </p>
            </NavLink>
          </div>
        ))}
      </Carousel>
    )
  );
}

export default DemoCarousel;
