import http from "./httpService";
import config from "./config";
const apiEndpoint = config.apiUrl + "/notification/send-order-email";
export function sendOrder(user) {
  return http.post(apiEndpoint, {
    email: user.email,
    user: user.user,
    quantity: user.quantity,
    tracking: user.tracking,
    amount: user.amount,
  });
}

// function userUrl(id) {
//   return `${apiEndpoint}/${id}`;
// }

// export function getCartById(userId) {
//   return http.get(userUrl(userId));
// }
// export function sendOrder() {
//   return http.post(apiEndpoint);
// }

// export function deleteCartById(userId) {
//   return http.delete(userUrl(userId));
// }

// export default {
//   addToCart,
//   getCartById,
//   deleteCartById,
// };
