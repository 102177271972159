import http from "./httpService";
import config from "./config";
const apiEndpoint = config.apiUrl + "/items";

function userUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getItemsById(Id) {
  return http.get(userUrl(Id));
}
export function getItems() {
  return http.get(apiEndpoint);
}

export default {
  getItems,
  getItemsById,
};
